import React, { useState, useEffect } from "react";
import axios from "axios";
import ImageList from "./ImageList";
import { useDebounce } from "../hooks/useDebounce";

const BASE_URL = "https://api.unsplash.com";
const apiKey = "G_580-CaAfPJAkpy09VD2OWTxooRNwcFyry0xdcnDXE";

const SearchField = () => {
  const [images, setImages] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const debounceValue = useDebounce(searchValue, 500);

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const fetchPhotos = async (searchTerm = "", page = 1) => {
    const url = `${BASE_URL}/search/photos?page=${page}&query=${searchTerm}&client_id=${apiKey}`;
    setIsLoading(false);
    try {
      const res = await axios.get(url);
      setImages((prev) => [...prev, ...res.data.results]);
      setIsLoading(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPhotos("technology");
  }, []);

  const handleButtonSearch = () => {
    setImages([]);
    if (searchValue.trim() !== "") {
      fetchPhotos(debounceValue);
    }
    //setSearchValue("");
  };

  return (
    <>
      <div className="flex justify-center items-center w-[50%] mx-auto">
        <input
          className="bg-gray-50 border border-gray-300 text-sm w-full indent-2 p-2.5 outline-none focus:border-blue-500 focus:ring-2 rounded-tl rounded-bl"
          type="search"
          placeholder="Search Anything..."
          value={searchValue}
          onChange={handleInputChange}
        />
        <button
          onClick={handleButtonSearch}
          disabled={!searchValue}
          className="border border-solid border-blue-600 text-blue-600 bg-white hover:bg-blue-600 px-6 cursor-pointer py-2.5 hover:text-white rounded-tr rounded-br focus:ring-2 focus:ring-blue-300 disabled:text-white disabled:border-gray-400 disabled:bg-gray-400"
        >
          Search
        </button>
      </div>
      <ImageList
        images={images}
        isLoading={isLoading}
        fetchData={() => fetchPhotos(debounceValue || "technology", 2)}
      />
    </>
  );
};

export default SearchField;
