import React from "react";

const Image = ({ image }) => {
  return (
    <a href={image.urls?.regular} target="_blank" rel="noreferrer">
      <img
        className="h-72 w-full object-cover rounded-lg shadow-md"
        src={image.urls?.small}
        alt="image_unplash"
      />
    </a>
  );
};

export default Image;
