import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Image from "./Image";
import Skeleton from "./Skeleton";

const ImageList = ({ images, fetchData, isLoading }) => {
  return (
    <>
      <h1 className="text-center mt-6 underline text-2xl">Results</h1>
      <InfiniteScroll
        dataLength={images?.length}
        next={typeof fetchData === "function" && fetchData}
        hasMore={true}
        loader={<div className="text-center">Loading...</div>}
      >
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 my-10 max-w-7xl mx-auto px-4">
          {!isLoading ? (
            <Skeleton item={10} />
          ) : (
            images.map((item, index) => <Image key={index} image={item} />)
          )}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default ImageList;
