import SearchField from "./components/SearchField";

function App() {
  return (
    <div className="App">
      <h2 className="text-center my-3 font-bold text-[30px]">
        Create Images App
      </h2>
      <SearchField />
    </div>
  );
}

export default App;
